<template>
    <div style="width:600px;margin-top: 60px;margin-left: 220px;padding: 30px 60px;border: 1px solid lightgray;">
        <Form ref="SubAccountForm" :disabled="!editmode" :model="formInline" :label-width="170" label-position="right" :rules="ruleInline">
            <Row  >
              <i-col span="24">
                <FormItem prop="finCkSaleplan" label="销售计划审核:">
                    <Select v-model="formInline.finCkSaleplan" >
                        <Option  value="false" key="0">不需要财务审核</Option>
                        <Option  value="true" key="1">需要财务审核</Option>
                    </Select>
                </FormItem>
              </i-col>
            </Row>
            <Row >                
              <i-col span="24" style="padding-top:15px;">
                <FormItem  prop="finCkSaleorderPay" label="销售订单收款审核:">
                    <Select v-model="formInline.finCkSaleorderPay"  >
                        <Option  value="false" key="0">不需要财务审核</Option>
                        <Option  value="true" key="1">需要财务审核</Option>
                    </Select>
                </FormItem>
              </i-col>
            </Row>
            <Row style="padding-top:15px;">  
              <i-col span="24">  
                <FormItem prop="purchaseHuaboValue" label="采购划拨预警值:">
                    <i-input type="number" v-model="formInline.purchaseHuaboValue"></i-input>
                </FormItem>                  
              </i-col>
            </Row>
        </Form>
        <div slot="footer">
            <Button type="primary" size="large" style="width: 130px;"  @click="handleSubmit('SubAccountForm')">{{editmode ? '保存':'点击修改'}}</Button>
            <Button v-if="editmode" type="error" ghost size="large" style="width: 130px;margin-left: 15px;"  @click="cancelmodify">取消修改</Button>
        </div>
    </div>
</template>
<script>
    export default {
        name:'ErpConfig',
        data () {
            return {
                editmode:false,
                formInline: {
                    finCkSaleplan: 'false',
                    finCkSaleorderPay:'true',
                    purchaseHuaboValue: 50
                },
                saveddata:null,
                ruleInline: {
                    finCkSaleplan: [
                        { required: true, message: '请选择是否需要审核', trigger: 'blur' },
                    ],
                    finCkSaleorderPay: [
                        { required: true, message: '请选择是否需要审核', trigger: 'blur' },
                    ],
                },
            }
        },
        mounted (){
            this.$axios.post("/api/MasterConfig/get")
            .then(function(res){
                if(res.data.resultCode ==0){
                    if(res.data.data !=null){
                        this.saveddata = JSON.parse(JSON.stringify(res.data.data))
                        this.formInline.finCkSaleplan = res.data.data.finCkSaleplan+''
                        this.formInline.finCkSaleorderPay = res.data.data.finCkSaleorderPay+''
                        this.formInline.purchaseHuaboValue = res.data.data.purchaseHuaboValue
                    }
                    else{
                        this.formInline = {
                            finCkSaleplan: 'false',
                            finCkSaleorderPay:'true',
                            purchaseHuaboValue: 50
                        }
                    }
                    
                }
                else{
                    this.$Message.error({content:res.data.msg, duration:3})
                }
            }.bind(this));            
        },
        methods: {
            cancelmodify(){
                this.formInline = JSON.parse(JSON.stringify(this.saveddata))
                this.editmode = false
            },
            handleSubmit (name) {
                if(!this.editmode){
                    this.editmode = true
                    return
                }

                this.$refs[name].validate((valid) =>{
                    if(valid){
                        let postdata = this.formInline
                        this.$axios.post('/api/MasterConfig/update',postdata)
                        .then(function(res){
                            if(res.data.resultCode ==0){
                                this.$Message.success({content:res.data.msg, duration:3})
                                this.editmode = false
                                this.saveddata = JSON.parse(JSON.stringify(this.formInline))
                            }
                            else{
                                this.$Message.error({content:res.data.msg, duration:3})
                            }
                        }.bind(this));   
                    }
                    else{
                        this.$Message.error("请输入正确的内容")
                    }
                })
            }
        }
    }
</script>
